@import "src/helpers/style/media-queries";
@import "src/helpers/style/mixins";
@import "src/helpers/text";

.atcscc-table-row:nth-child(odd) {
  background-color: #f5f5f5;
}

.atcscc-table-element {
  border: none;
  padding: 0.2rem;
}

.atcscc-event-text {
  @include small-text;
  text-align: left;
}

.atcscc-event {
  width: 100%;
}
