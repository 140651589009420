@import "src/helpers/style/media-queries";
@import "src/helpers/style/mixins";
@import "src/helpers/text";

.app-header {
  background: #003e7e;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: $headerFooterHeight;
  padding: 0 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);

  > *:first-child,
  > *:last-child {
    flex: 10%;
  }

  @include respond-below("tablet") {
    padding: 0 1rem;
  }
}

.logo-container {
  display: flex;
  align-items: center;
  color: #ffffff;
  height: $headerFooterHeight;

  @include respond-above("laptop") {
    width: 50%;
  }
  @include respond-below("laptop") {
    width: 6rem;
  }

  &:hover {
    cursor: pointer;
    background: #15396c;
  }
}

.logo {
  max-width: 6.25rem;
  min-width: 4.75rem;
  width: 100%;
}

.faa-home-link {
  text-decoration: none;
}

.faa-name {
  @include body-text;
  font-weight: bold;
  padding-left: 0.75rem;
  max-width: 10rem;

  @include respond-below("laptop") {
    display: none;
  }

  a:link {
    text-decoration: none;
  }
}

.app-title {
  @include site-title;
  text-align: center;
  padding: 0 1rem;
  font-weight: normal;

  //This is specifically for the iPhone 5
  @include respond-below("phone") {
    padding: 0.4rem;
    font-size: 1.1rem;
  }
}

.menu-container {
  display: flex;
  justify-content: flex-end;
}

.menu {
  &:hover {
    cursor: pointer;
  }

  @include respond-above("laptop") {
    display: none;
  }
}

.vertical-time-block {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @include respond-below("laptop") {
    display: none;
  }
}

.horizontal-time-block {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include respond-above("laptop") {
    display: none;
  }
}

.current-time {
  background: #815c00;
}

.incrementing-time {
  @include label-text;
  text-align: right;
  padding: 0.25rem;
  margin: 0.25rem;

  @include respond-below("tablet") {
    margin: unset;
    text-align: center;
  }
}

.navigation-bar {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  background: #0076c0;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;

  a:hover {
    background-color: #003e7e;
  }

  > * + * {
    padding-left: 3rem;
  }

  @include respond-below("laptop") {
    display: none;
  }
}

.header-external-link {
  text-decoration: none;
  font-size: 1.125rem;
  color: inherit;

  @include respond-above("desktop") {
    padding: 1.32rem;
  }

  @include respond-below("desktop") {
    padding: 1.4rem;
  }

  @include respond-below("laptop") {
    padding: 0;
  }
}
