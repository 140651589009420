@import "src/helpers/style/media-queries";

@mixin site-title {
  font-size: 2.5rem;

  @include respond-below("desktop") {
    font-size: 1.75rem;
  }

  @include respond-below("laptop") {
    font-size: 1.7rem;
  }

  @include respond-below("tablet") {
    font-size: 1.25rem;
  }
}

@mixin section-title {
  font-size: 1.5rem;

  @include respond-below("desktop") {
    font-size: 1.25rem;
  }
}

@mixin subtitle {
  font-size: 1.1rem;

  @include respond-between("tablet", "desktop") {
    font-size: 0.9rem;
  }

  @include respond-below("tablet") {
    font-size: 0.8rem;
  }
}

@mixin map-title {
  font-size: 1.2rem;

  @include respond-between("tablet", "desktop") {
    font-size: 1rem;
  }

  @include respond-below("tablet") {
    font-size: 0.9rem;
  }
}

@mixin map-subtitle {
  font-size: 1rem;

  @include respond-between("tablet", "desktop") {
    font-size: 0.9rem;
  }

  @include respond-below("tablet") {
    font-size: 0.8rem;
  }
}

@mixin body-text {
  font-size: 1.1rem;

  @include respond-below("desktop") {
    font-size: 0.9rem;
  }
}

@mixin very-small-text {
  font-size: 0.7rem;

  @include respond-below("tablet") {
    font-size: 0.5rem;
  }
}

@mixin small-text {
  font-size: 1rem;

  @include respond-below("tablet") {
    font-size: 0.75rem;
  }
}

@mixin label-text {
  font-size: 1.125rem;

  @include respond-between("tablet", "desktop") {
    font-size: 0.87rem;
  }

  @include respond-below("tablet") {
    font-size: 0.7rem;
  }

  //This is specifically for the iPhone 5
  @include respond-below("phone") {
    font-size: 0.55rem;
  }
}

@mixin footer-header {
  font-size: 1.5rem;

  @include respond-between("tablet", "desktop") {
    font-size: 1.3rem;
  }

  @include respond-below("tablet") {
    font-size: 1.2rem;
  }
}

@mixin list-view-small-text {
  font-size: 1rem;

  @include respond-between("tablet", "desktop") {
    font-size: 0.8rem;
  }

  @include respond-below("tablet") {
    font-size: 0.7rem;
  }
}
