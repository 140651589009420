@import "src/helpers/style/media-queries";
@import "src/helpers/style/mixins";
@import "src/helpers/text";

.forecast-card {
  flex-basis: 46.5%;

  @include respond-below("laptop") {
    flex-basis: 100%;
  }
}

.forecast-table {
  border-style: none;
  empty-cells: hide;
  width: 100%;
}

.forecast-table-caption {
  font-weight: bold;
  padding-bottom: 1rem;
  font-size: 1.2rem;
}

.forecast-table-element {
  border: none;
  padding: 0.2rem 1rem;
}

.forecast-table-header {
  background: #0076c0;
  color: #ffffff;
}

.forecast-table-row:nth-child(odd) {
  background-color: #f5f5f5;
}

.forecast-event-text {
  @include small-text;
  text-align: left;
}

.forecast-time {
  @include small-text;
  text-align: center;
  width: 30%;
}

.empty-cell {
  border: none;
  width: 2%;
  background: #ffffff;
}

.forecast-event {
  width: 68%;
}
