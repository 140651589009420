.acceptance {
  .acceptance-header {
    background-color: #282c34;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  li {
    margin-left: 40px;
  }

  div {
    padding: 10px;
  }
}

.load-button {
  background-color: #4caf50;
  border: #000000;
  color: #ffffff;
  padding: 1rem 1rem 1rem 1rem;
  font-size: 1rem;
  margin-top: 1rem;
  margin-left: 2rem;
}
