@import "src/helpers/style/media-queries";
@import "src/helpers/style/mixins";

.legend-popup-button-div {
  padding-left: 1%;

  @include respond-below("desktop") {
    position: absolute;
    margin-right: 40%;
    left: 0;
  }

  @include respond-below("laptop") {
    position: absolute;
    margin-right: 35%;
  }

  @include respond-above("tablet") {
    display: none;
  }

  @include respond-below("tablet") {
    position: absolute;
    margin-right: 20%;
  }

  @include respond-below("phone") {
    position: absolute;
    margin-right: 15%;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: none;
  }
}

.legend-button {
  border: transparent;
}

.legend-popup {
  padding-left: 0.3rem;
}

.legend-button-image {
  width: 60%;
  height: 60%;
}
