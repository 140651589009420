@import "src/helpers/style/media-queries";
@import "src/helpers/style/mixins";
@import "src/helpers/text";

.app-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.error-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include respond-below("tablet") {
    align-items: center;
  }
}

.error-page-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.error-image {
  padding-right: 1rem;

  @include respond-below("tablet") {
    display: none;
  }
}

.error-code {
  font-size: 4rem;
  font-weight: bold;
  padding: 0.5rem;
}

.error-title {
  font-size: 1.5rem;
  padding: 0.5rem;
}

.error-message {
  font-size: 0.87rem;
  padding: 0.5rem;
}
