@import "src/helpers/style/media-queries";
@import "src/helpers/style/mixins";
@import "src/helpers/text";

.event-blocks {
  padding: 0 0.5rem;
  margin: 1rem auto 0;
  width: 99%;

  @include respond-between("tablet", "laptop") {
    width: 98%;
  }

  @include respond-below("tablet") {
    width: 95%;
  }
}

.card-list {
  display: flex;
}

.empty-state {
  @include section-title;
  text-align: center;
  color: #555555;
  padding: 0.2rem 0;
  width: 100%;
}
.airport-events {
  flex-flow: row wrap;
  justify-content: flex-start;

  @include respond-below("tablet") {
    justify-content: center;
  }
}

.enroute-events {
  flex-flow: row wrap;
}

.forecast-events {
  flex-flow: row wrap;
  justify-content: left;
}

.atcscc-events {
  flex-flow: row wrap;
  display: block;
}

.empty-forecast-events {
  @include subtitle;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  flex-basis: 46.5%;
  outline: 1px dashed #000000;

  padding: 1rem 1.25%;
  margin: 0.5rem $cardHorizontalMargin;

  @include respond-below("laptop") {
    flex-basis: 100%;
  }
}

.operations-plan-link {
  @include body-text;

  color: #344da6;
  text-decoration: none;
  font-weight: bold;
  margin: 0.5rem 0;
}

.operations-plan-link:hover {
  text-decoration: underline;
}

.operations-plan {
  text-align: center;
}
