@import "src/helpers/text";

.airport-card-header {
  display: flex;
}

.airport-card-block {
  padding: 0.2rem 0;
}

.airport-card-block + .airport-card-block {
  border-top: 1px solid #cfcfcf;
  padding-top: 0.75rem;
  margin-top: 0.75rem;
}

.airport-card-content {
  display: flex;
  justify-content: space-between;

  + * {
    padding-top: 0.25rem;
  }
}
.airport-card-link {
  padding-top: 0.5rem;
  padding-right: 1rem;
}

.airport-links-container {
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  position: relative;
  align-items: baseline;

  @media screen and (min-width: 300px) and (max-width: 1600px) {
    flex-wrap: wrap;
  }
}

.airport-name {
  @include section-title;
  font-weight: bold;
  padding-bottom: 0.3rem;
}

.event-type {
  @include subtitle;
  font-weight: bold;
}

.high-severe {
  color: #c91010;
}

.medium-severe {
  color: #b85e0b;
}

.view-details-button {
  background: none;
  color: #2a4297;
  border: none;
  padding-bottom: 0.3rem;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  font-size: 1.125rem;
}

.view-details-button:hover {
  text-decoration-line: underline;
}
