@import "src/helpers/style/mixins";
@import "src/helpers/style/media-queries";
@import "src/helpers/text";

.section-header {
  @include section-title;
  margin: 0 $cardHorizontalMargin 1rem;
  padding-bottom: 0.75rem;
  border-bottom: 0.5px solid #000000;
  font-weight: normal;
  text-align: left;
}
