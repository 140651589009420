@import "src/helpers/style/media-queries";
@import "src/helpers/text";

.beta-banner {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  background: #ffbb59;
  height: 4rem;
  line-height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-size: 1.4rem;
  font-style: italic;

  @include respond-below("desktop") {
    padding: 0.5rem;
    height: 3rem;
    font-size: 1.25rem;
  }

  @include respond-below("tablet") {
    padding: 0.25rem;
    height: 1.3rem;
    font-size: 0.7rem;
  }

  @media screen and (min-width: 270px) and (max-width: 360px) {
    line-height: 0.8rem;
  }
}

.beta-banner-span {
  display: flex;
  justify-content: center;
  align-items: center;

  @include respond-below("tablet") {
    display: inline;
  }
}

.plane-departure-icon {
  height: 2rem;
  padding-right: 1rem;

  @include respond-below("tablet") {
    height: 1.2rem;
    padding-right: 0;
  }

  @media screen and (min-width: 370px) and (max-width: 389px) {
    line-height: 0.8rem;
  }

  @media screen and (min-width: 390px) and (max-width: 420px) {
    line-height: 1rem;
  }
}

.beta-banner-link {
  padding-left: 0.2rem;
  color: #344da6;
  text-decoration: none;
}

.beta-banner-link:hover {
  text-decoration: underline;
}
