@import "src/helpers/style/media-queries";
@import "src/helpers/text";

.delay-modal {
  display: flex;
  flex-direction: column;
  width: 22rem;
  align-items: center;
  align-content: center;
  position: absolute;
  top: 30%;
  left: 40%;
  background-color: white;
  padding: 0.8rem 0.5rem;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  outline: none;

  @include respond-between("tablet", "laptop") {
    top: 25%;
    left: 30%;
  }

  @include respond-below("tablet") {
    width: 95%;
    top: 15%;
    left: 0;
  }
}

.delay-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(240, 240, 240, 0.75);
  display: flex;
  overflow-y: scroll;
  z-index: 9999;

  align-items: center;
  justify-content: center;
}

.delay-table {
  align-content: center;
  text-align: center;
  width: 100%;
}

.delay-table-caption {
  background: #f0f0f0;
  margin: 0.3rem 0;
  font-weight: bold;
}

.delay-table-element {
  padding: 0.4rem;
}

.delay-table-close {
  background: none;
  border: none;
  align-self: flex-end;
  color: #344da6;
  font-size: 1rem;
}

.delay-table-close:hover {
  cursor: pointer;
}

.delay-table-description {
  font-size: 1rem;
}
.delay-trend-button {
  @include body-text;

  background: none;
  border: none;
  color: #344da6;
  font-size: 1.125rem;
  font-family: "Lato", sans-serif;
}

.delay-trend-button:hover {
  cursor: pointer;
  text-decoration: underline;
}

h2 {
  font-family: "Lato", sans-serif;
  font-weight: 400;
}
