@import "src/helpers/style/media-queries";
@import "src/helpers/style/mixins";
@import "src/helpers/text";

.home {
  display: flex;
  margin: 0 auto;
  flex-flow: column wrap;
}

.local-time {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  justify-content: flex-end;
  padding: 0.72rem 1.1rem 0 0;

  @include respond-below("laptop") {
    padding-right: 1rem;
  }
  @include respond-below("tablet") {
    padding-right: 0.5rem;
  }
}

.home-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0.72rem 1.1rem 0 0;

  @include respond-below("laptop") {
    padding: 0.72rem 1rem 0 0;
  }
}

.home-buttons-container-internet-explorer {
  display: flex;
  flex-direction: row;
  padding: 0.72rem 1.1rem 0 0;

  @include respond-below("laptop") {
    padding: 0.72rem 1rem 0 0;
  }
}

.view-navigation-container {
  display: flex;
  flex-direction: row;
  padding: 1rem 30% 0 41%;

  @include respond-below("laptop") {
    padding: 1rem 0 0.5rem 35%;
    margin-right: 20%;
    width: 60%;
  }

  @include respond-below("tablet") {
    padding: 1rem 0 0.5rem 20%;
    margin-right: 12%;
    width: 60%;
  }
}

.view-navigation-button {
  outline: none;
  border: 0px;
  background: none;
  font-weight: bold;
  font-size: medium;
  margin: 0 1rem 0 1rem;
  color: black;
}

.view-navigation-button:hover {
  cursor: pointer;
}

.view-navigation-button-active {
  outline: none;
  border: 0px;
  background: none;
  font-weight: bold;
  font-size: medium;
  margin: 0 1rem 0 1rem;
  color: #2a4297;
  text-decoration: underline;
}

.view-navigation-button-active:hover {
  cursor: pointer;
}

label {
  @include very-small-text;
  color: #111111;
  font-weight: 500;
}

label:hover {
  cursor: pointer;
}
