@import "src/helpers/style/media-queries";
@import "src/helpers/text";

.webmap-scope {
  width: 45rem;
  height: 35rem;
  background-color: #474749;
  border: 2px solid black;

  @include respond-between("tablet", "laptop") {
    width: 35rem;
    height: 27rem;
  }

  @include respond-below("tablet") {
    width: 19rem;
    height: 15rem;
  }

  @media screen and (min-width: 1026px) and (max-width: 1270px) {
    width: 41.8rem;
    height: 26rem;
  }
}

.scope-modal {
  display: flex;
  flex-direction: column;
  width: 50rem;
  align-items: center;
  align-content: center;
  position: absolute;
  left: 29%;
  background-color: #f0f0f0;
  padding: 0.8rem 0.5rem;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  outline: none;
  max-height: 100%;

  @include respond-between("tablet", "laptop") {
    width: 37rem;
    top: 20%;
    left: 16%;
  }

  @include respond-below("tablet") {
    width: 20rem;
    top: 16%;
    left: 7%;
  }

  @media screen and (min-width: 1025px) and (max-width: 1270px) {
    width: 45rem;
    top: 16%;
    left: 20%;
  }
}

.scope-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(240, 240, 240, 0.75);
  display: flex;
  z-index: 9999;
  border: 1px solid black;
  align-items: center;
  justify-content: center;
}

.scope-map-close {
  background: none;
  border: none;
  align-self: flex-end;
  color: #344da6;
  font-size: 1rem;
}

.scope-map-close:hover {
  cursor: pointer;
}

.scope-button {
  @include body-text;
  font-family: "Lato", sans-serif;

  background: none;
  border: none;
  color: #344da6;
  font-size: 1.125rem;
}

.scope-button:hover {
  cursor: pointer;
  text-decoration: underline;
}

.scope-facilities {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  border: 2px solid black;
  border-bottom: 0;
  box-sizing: border-box;
  width: 45.25rem;
  text-align: center;

  @include respond-between("tablet", "laptop") {
    width: 35.25rem;
  }

  @include respond-below("tablet") {
    width: 19.25rem;
  }

  @media screen and (min-width: 1025px) and (max-width: 1270px) {
    width: 42rem;
  }
}

.scope-logo {
  vertical-align: text-bottom;
  margin-right: 0.2rem;
}
