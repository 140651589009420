@import "src/helpers/style/media-queries";
@import "src/helpers/style/mixins";
@import "src/helpers/text";

.web-main-map {
  height: 50rem;
  width: 98.7%;
  background-color: #474749;
  border: 0.2rem solid #555;

  @include respond-below("laptop") {
    height: 50rem;
    width: 98%;
  }
  @include respond-below("tablet") {
    height: 30rem;
    width: 96%;
  }
}

.map-card-content {
  display: flex;
  justify-content: space-between;
}

.map-card-description {
  padding-top: 0.3rem;
}

.map-card-block + .map-card-block {
  border-top: 1px solid #cfcfcf;
  padding-top: 0.5rem;
  margin-top: 0.75rem;
}

.map-card-title {
  @include map-title;
  font-weight: bold;
}

.map-event-type {
  @include map-subtitle;
  font-weight: bold;
}

.high-severe-map {
  color: #c91010;
}

.medium-severe-map {
  color: #b85e0b;
}

.low-severe-map {
  color: black;
}

.map-event-time-block {
  @include map-subtitle;
  font-weight: normal;
}

.map-event-time {
  @include map-subtitle;
  font-weight: bold;
}
