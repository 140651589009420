@import "src/helpers/style/media-queries";
@import "src/helpers/style/mixins";
@import "src/helpers/text";

.list-view-card {
  flex-basis: 46.5%;

  @include respond-below("laptop") {
    flex-basis: 100%;
  }
}
.list-view-table {
  border-style: none;
  empty-cells: hide;
  width: 100%;

  @media screen and (min-width: 300px) and (max-width: 1000px) {
    display: block;
    overflow-x: auto;
  }
}

.list-view-table-row:nth-child(odd) {
  background-color: #f5f5f5;
  height: 2.5rem;
}

.list-view-table-row:nth-child(even) {
  background-color: white;
  height: 2.5rem;
}

.list-view-table-header {
  background: #0076c0;
  color: #ffffff;
  border: none;
  text-align: left;
  padding-bottom: 1rem;
}

.list-view-table-padding-left {
  padding-left: 1rem;
}

.list-view-table-padding-right {
  padding-right: 1rem;
}

.list-view-table-scope-padding {
  @include respond-below("laptop") {
    padding-right: 5rem;
  }
}

.list-view-header-text {
  @include label-text;
  margin-top: 0.5rem;
  padding-right: 0.5rem;
}

.list-view-second-header-element {
  @include label-text;
}

.list-view-table-border {
  border: none;
  @include list-view-small-text;
}

.list-view-table-airport-text {
  @include list-view-small-text;
  font-weight: bold;
  border: none;
  padding-left: 1rem;
}

.list-view-table-event-text-high {
  @include list-view-small-text;
  border: none;
  padding-left: 1rem;
  padding-right: 1rem;
  color: #c91010;
  font-weight: bold;
}

.list-view-table-event-text-medium {
  @include list-view-small-text;
  border: none;
  padding-left: 1rem;
  padding-right: 1rem;
  color: #b85e0b;
  font-weight: bold;
}

.list-view-table-event-text-low {
  @include list-view-small-text;
  border: none;
  padding-left: 1rem;
  padding-right: 1rem;
  font-weight: bold;
}

.list-view-table-advisory-link {
  transform: scale(0.8);
}

.list-view-table-delay-trend-link {
  transform: scale(0.8);
}

.list-view-table-scope-link {
  transform: scale(0.8);
}

.list-view-link-align {
  display: table-cell;
  vertical-align: middle;
  white-space: nowrap;
  position: relative;
  z-index: 9000;
}

.list-view-table-fixed {
  @media screen and (min-width: 300px) and (max-width: 990px) {
    left: 0;
    position: sticky;
    z-index: 9998;
    padding-right: 0.3rem;
  }
}
.list-view-table-fixed-second {
  @media screen and (min-width: 300px) and (max-width: 990px) {
    position: sticky;
    z-index: 9998;
    left: 4.3rem;
  }

  @media screen and (min-width: 300px) and (max-width: 650px) {
    left: 3.85rem;
    padding-left: 1rem;
  }
}

table tr:nth-child(odd) td:nth-child(1) {
  @media screen and (min-width: 300px) and (max-width: 990px) {
    background-color: #f5f5f5;
    left: 0;
    position: sticky;
    z-index: 9998;
  }
}

table tr:nth-child(odd) td:nth-child(3) {
  @media screen and (min-width: 300px) and (max-width: 990px) {
    background-color: #f5f5f5;
    left: 4.4rem;
    position: sticky;
    z-index: 9998;
  }
  @media screen and (min-width: 300px) and (max-width: 650px) {
    left: 3.8rem;
  }
}

table tr:nth-child(even) td:nth-child(1) {
  @media screen and (min-width: 300px) and (max-width: 990px) {
    background-color: white;
    left: 0;
    position: sticky;
    z-index: 9998;
  }
}

table tr:nth-child(even) td:nth-child(3) {
  @media screen and (min-width: 300px) and (max-width: 990px) {
    background-color: white;
    left: 4.4rem;
    position: sticky;
    z-index: 9998;
  }

  @media screen and (min-width: 300px) and (max-width: 650px) {
    left: 3.8rem;
  }
}
