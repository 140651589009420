@import "src/helpers/style/media-queries";
@import "src/helpers/style/mixins";
@import "src/helpers/text";

.card {
  display: flex;
  flex-flow: column nowrap;

  background: white;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;

  padding: 1rem 1.25%;
  margin: 0.5rem $cardHorizontalMargin;
  flex-basis: 29.8%;

  @include respond-between("tablet", "laptop") {
    flex-basis: 46.5%;
  }

  @include respond-below("tablet") {
    flex-basis: 100%;
    margin: 0.5rem $cardHorizontalMargin;
    padding: 1rem 2.5%;
  }
}

.card-external-link {
  @include body-text;
  font-size: 1.125rem;
  color: #344da6;
  text-decoration: none;
}

.card-external-link:hover {
  text-decoration: underline;
}
