@import "src/helpers/text";
@import "src/helpers/style/media-queries";

.afp-card {
  flex-basis: 46.5%;

  @include respond-below("laptop") {
    flex-basis: 100%;
  }
}

.map-block {
  width: 50%;
  margin-right: 1rem;
  @include respond-below("tablet") {
    width: 100%;
  }
}

.afp-info-block {
  width: 50%;
  position: relative;

  @include respond-below("tablet") {
    width: 100%;
  }
}

.afp-header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.afp-header-column {
  @include section-title;
  font-weight: bold;
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
}

.empty-afp-div {
  height: 100%;
}

.afp-name-large {
  @include subtitle;
  display: inline;

  @include respond-below("tablet") {
    display: none;
  }
}

.afp-name-small {
  @include subtitle;
  display: none;

  @include respond-below("tablet") {
    display: inline;
  }
}

.afp-links-container {
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  position: relative;
  align-items: baseline;

  @media screen and (min-width: 300px) and (max-width: 1600px) {
    flex-wrap: wrap;
  }
}

.afp-external-link {
  padding-top: 0.5rem;
  padding-right: 2rem;
}

.afp-card-block {
  padding-top: 0.75rem;
}

.afp-card-info-container {
  @include respond-above("tablet") {
    display: flex;
    flex-direction: row;
  }
}

.afp-card-block + .afp-card-block {
  border-top: 1px solid #cfcfcf;
  padding-top: 0.75rem;
  margin-top: 0.75rem;
}

.afp-links-container + .afp-card-block {
  border-top: 1px solid #cfcfcf;
  padding-top: 0.75rem;
  margin-top: 0.75rem;
}

.afp-altitude-section {
  padding-bottom: 0.5rem;
}
