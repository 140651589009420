@import "src/helpers/text";

.runway-content-header {
  @include subtitle;
  font-weight: bold;
}

.runway-card {
  display: flex;
  justify-content: left;

  + * {
    padding-top: 0.25rem;
  }

  div:nth-child(2) {
    padding-left: 2rem;
  }

  div:nth-child(3) {
    padding-left: 2rem;
  }
}

.runway-content {
  flex-direction: column;
}

.runway-text {
  font-size: small;
}

.runway-content-values {
  @include subtitle;
  font-size: 1.5rem;
  font-weight: bold;
}
