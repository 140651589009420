@import "src/helpers/style/media-queries";
@import "src/helpers/text";

.event-time-block {
  @include subtitle;
  font-weight: normal;
}

.event-time {
  @include subtitle;
  font-weight: bold;
}
