@import "src/helpers/text";

.badge {
  @include body-text;
  font-weight: bold;
  color: #ffffff;

  width: auto;
  padding: 0 0.5rem;
  height: 2rem;
  border-radius: 4px;
  background: #00809b;
  margin-left: 0.5rem;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  @include respond-between("tablet", "desktop") {
    height: 1.6rem;
  }

  @include respond-below("tablet") {
    height: 1.5rem;
  }
}

.list-view-table-badge {
  @include very-small-text;
  font-weight: bold;
  color: #ffffff;

  width: auto;
  padding: 0 0.25rem;
  height: 1rem;
  border-radius: 2px;
  background: #00809b;
  margin-left: 0.25rem;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  @include respond-between("tablet", "desktop") {
    height: 0.8rem;
  }

  @include respond-below("tablet") {
    height: 0.7rem;
  }
}
