@import "src/helpers/style/media-queries";

.map-view-buttons-div {
  display: flex;
  flex-direction: row;
  margin-top: 0.5%;
  margin-bottom: -1%;

  @include respond-above("laptop") {
    margin-bottom: -1.1%;
  }

  @include respond-below("desktop") {
    margin-top: -0.5%;
    margin-bottom: -0.8%;
  }
  @include respond-above("mobile") {
    align-items: center;
    margin-left: 2rem;
  }
  @include respond-below("laptop") {
    margin-top: -1%;
    margin-bottom: -2%;
    margin-left: -0.45rem;
  }
  /* For Small Tablets */
  @media screen and (min-width: 650px) and (max-width: 730px) {
    margin-top: -1.8%;
    margin-bottom: -3.4%;
    margin-left: -0.8rem;
  }
  @include respond-below("tablet") {
    margin-left: 0;
  }

  /* For Slightly Small Tablets */
  @media screen and (min-width: 731px) and (max-width: 900px) {
    margin-top: -1.5%;
    margin-bottom: -2.7%;
    margin-left: -0.7rem;
  }

  /* For Medium Tablets */
  @media screen and (min-width: 901px) and (max-width: 1000px) {
    margin-top: -0.5%;
    margin-bottom: -2%;
    margin-left: 0.1rem;
  }

  /* For Slightly Medium Tablets */
  @media screen and (min-width: 1001px) and (max-width: 1150px) {
    margin-top: -0.5%;
    margin-bottom: -1.1%;
    margin-left: 0rem;
  }

  @media screen and (min-width: 1151px) and (max-width: 1450px) {
    margin-left: -0.22%;
    margin-bottom: -0.5%;
  }
  @media screen and (min-width: 1451px) and (max-width: 1700px) {
    margin-left: 10%;
    margin-bottom: -1.5%;
  }
  @media screen and (min-width: 2400px) and (max-width: 9096px) {
    margin-bottom: -0.5%;
  }
}

.main-map-container {
  padding: 1rem 2% 0 2%;
  display: flex;
  justify-content: center;
}

.desktop-legend {
  width: 99%;
  margin-left: 1.5rem;

  @include respond-below("desktop") {
    margin-left: 1.5rem;
  }

  @include respond-below("laptop") {
    transform: scaleY(1.2);
  }

  @include respond-below("tablet") {
    display: none;
    visibility: collapse;
  }

  /* For Slightly Medium Tablets */
  @media screen and (min-width: 901px) and (max-width: 1100px) {
    transform: scaleY(1.3);
    transform: scaleX(1.02);
  }

  @media screen and (min-width: 1451px) and (max-width: 1700px) {
    transform: scale(1.3);
    margin-right: 6rem;
  }
}

.mobile-legend {
  height: 50%;
  width: 100%;

  @include respond-above("tablet") {
    display: none;
    visibility: collapse;
  }
  @include respond-below("laptop") {
    height: 50%;
  }
}

.map-legend {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  padding-left: 1%;

  @include respond-above("laptop") {
    margin-bottom: 1rem;
  }

  @include respond-below("desktop") {
    height: 100%;
    margin-bottom: -0.3rem;
  }
  @include respond-below("laptop") {
    margin-bottom: 0;
  }
  @include respond-below("tablet") {
    width: 0;
  }
}

.ie-map-legend {
  padding-left: 1.45%;
  display: none;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: block;
  }
}

.ie-map-legend-button {
  width: 24px;
  height: 24px;
  display: none;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: block;
  }
  @include respond-above("phone") {
    display: none;
    visibility: collapse;
  }
}

.map-artcc-button-div {
  display: flex;
  flex-direction: row;
  margin-right: 5%;
  padding-right: 1%;

  @include respond-below("desktop") {
    height: 100%;
  }
  @include respond-below("laptop") {
    margin-right: 0px;
  }

  @media screen and (min-width: 1451px) and (max-width: 1700px) {
    margin-left: 2%;
  }
}

.map-artcc-button {
  height: 100%;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    transform: scale(1) !important;
    height: auto !important;
    width: auto !important;
  }
  @include respond-above("laptop") {
    margin-left: 2rem;
    margin-top: -0.1rem;
    transform: scale(0.8);
  }
  @include respond-above("tablet") {
    padding-left: 1rem;
  }
  @include respond-below("desktop") {
    height: 30%;
    width: 64px;
    margin-top: 0.8rem;
    margin-right: -1rem;
    transform: scale(1.4);
  }
  @include respond-below("laptop") {
    height: 30%;
    margin-top: 0.7rem;
    margin-right: -1rem;
    transform: scale(1);
  }
  @include respond-below("tablet") {
    height: 30%;
    width: 64px;
    margin-top: 0;
    margin-right: 0;
    margin-left: 3rem;
    transform: scale(1);
  }
  /* For Small Tablets */
  @media screen and (min-width: 660px) and (max-width: 720px) {
    .view {
      width: 0;
    }
    transform: scaleY(0.85);
  }

  /* For Slightly Medium Tablets */
  @media screen and (min-width: 1025px) and (max-width: 1150px) {
    margin-left: 85%;
  }

  @media screen and (min-width: 1151px) and (max-width: 1450px) {
    margin-left: 90%;
  }

  @media screen and (min-width: 1451px) and (max-width: 1700px) {
    margin-left: 80%;
  }

  @media screen and (min-width: 1701px) and (max-width: 9096px) {
    margin-left: 90%;
  }
}
